<template>
  <div class="container">
    <div class="wave-background">
      <div class="main">
        <nav>
          <button id="home-btn"><img id="logo" src="/img/hid-logo.png" alt="" /></button>
        </nav>
        <div>
          <h4 class="title-tag">CITIZEN ID</h4>
          <h1 class="title">Interactive Tools</h1>
        </div>
        <div class="cards-container">
          <CardComponent v-for="card in cards" :key="card.index" :card="card" :class="{ 'hide-on-mobile': card.hideOnMobile }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "./components/CardComponent.vue";

export default {
  name: "App",
  components: {
    CardComponent,
  },

  data() {
    return {
      cards: [
        {
          title: "3D Passport",
          img: "/img/3D-passport.png",
          link: "https://passport.cid.hidglobal.com/",
          hideOnMobile: false,
        },
        {
          title: "DTC Quiz",
          img: "/img/dtc-quiz.png",
          link: "https://dtcquiz.cid.hidglobal.com/",
          hideOnMobile: false,
        },
        {
          title: "Datapage Puzzle",
          img: "/img/datapage-puzzle.png",
          link: "https://datapagepuzzle.cid.hidglobal.com/",
          hideOnMobile: true,
        },
        {
          title: "Solutions Builder",
          img: "/img/solutions-builder.png",
          link: "https://solutions.cid.hidglobal.com/",
          hideOnMobile: true,
        },
      ],
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  /*
  touch-action: none;
  -ms-touch-action: none;
  */
}

html,
body {
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.container {
  min-height: 100vh;
  min-width: 100vw;
  background: var(--Gradient-01, linear-gradient(180deg, #002d56 38.38%, #0057b7 132.81%));
}

.main {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.wave-background {
  background-image: url("../public/img/waves.png");
  background-size: 100% 70%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  height: 100%;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.cards-container {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 960px;
  margin-bottom: 15px;
}

h1 {
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 3rem;
}

h1.title {
  margin: 0px 0px 40px;
  text-align: center;
}

h2 {
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 2rem;
}

h4 {
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 700;
  color: #edc417;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  text-align: center;
}

h4.title-tag {
  margin: 30px 0px 10px;
}

#logo {
  height: 40px;
}

#home-btn {
  background-color: transparent;
  border: none;
}

a.button {
  background-color: #edc417;
  color: #002d56;
  font-family: "neue-haas-unica", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  width: 25rem;
  height: 3.5rem;
  border-radius: 6rem;
  border: none;
  margin: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1rem;
  }

  #logo {
    height: 30px;
  }

  nav {
    height: 3.125rem;
  }
}

.hide-on-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
