<template>
  <div class="card" @click="cardClicked">
    <div class="card-inner-wrapper">
      <h2 class="card-title">{{ card.title }}</h2>
      <div class="card-img-wrapper">
        <img :src="card.img" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: ["card"],
  methods: {
    cardClicked() {
      window.open(this.card.link, "_blank");
    },
  },
};
</script>

<style>
.card {
  margin: 15px;
  cursor: pointer;
  width: 28rem;
  height: 16rem;
}
.card-inner-wrapper {
  height: 100%;
  border-radius: 1.25rem;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  background: var(--Gradient-01, linear-gradient(180deg, #002d56 38.38%, #0057b7 132.81%));
}

h2.card-title {
  padding: 15px 0 0 25px;
}

.card-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.image {
  height: 100%; /* Ensures the image width does not exceed the card container */
  max-height: 200px; /* Example max-height, adjust as needed */
  object-fit: cover; /* Maintains aspect ratio, may crop the image */
  display: block; /* Removes any extra space below the image */
}

@media (max-width: 768px) {
  .card {
    width: 22rem;
    height: 12rem;
  }

  h2.card-title {
    font-size: 1.5rem;
  }

  .image {
    max-width: 22rem;
    max-height: 9.3rem;
  }
}
</style>
